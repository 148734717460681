/** 个人信息主视图 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.v-customer-userInfo-views {
  margin-left: 120px;
  margin-top: 50px;
}
</style>
<template>
  <div class="v-customer-userInfo-views" v-loading="submit_loading">
    <!-- 用户表单信息 -->
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-position="labelPosition" :inline="true" label-width="50px">
      <el-form-item label="账号名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入账号名称"></el-input>
      </el-form-item>
      <br/>
      <el-form-item label="登陆邮箱" prop="email">
        <el-input v-model="ruleForm.email" placeholder="请输入登陆邮箱"></el-input>
      </el-form-item>
      <el-form-item label="登陆密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" placeholder="请输入登录密码"></el-input>
      </el-form-item>
      <br/>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <br/>
      <el-form-item label="公司名称" prop="company">
        <el-input v-model="ruleForm.company" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="公司网站" prop="website">
        <el-input v-model="ruleForm.website" placeholder="请输入公司网站"></el-input>
      </el-form-item>
      <br/>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validatePhone } from '../../../validate';
import APIs from '../../../APIs';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'userInfo-views',
  data () {
    return {
      labelPosition: 'top',
      submit_loading: false,
      ruleForm: {
        name: '', /// 必填
        email: '', /// 必填
        password: '', /// 必填
        phone: '',
        company: '',
        website: ''
      }, // 表单数据
      rules: { // 表单数据验证规则
        name: [{
          required: true,
          message: '请输入账号名称',
          trigger: 'change'
        }],
        email: [
          {
            required: true,
            message: '请输入登陆邮箱',
            trigger: 'change'
          },
          {
            type: 'email',
            message: '邮箱格式不合法',
            trigger: 'change'
          }
        ],
        password: [
          {
            min: 6,
            message: '密码长度不能小于6位',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'change'
          },
          {
            validator: validatePhone,
            trigger: 'change'
          }
        ] /** ,
        company: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'change'
        }],
        website: [{
          required: true,
          message: '请输入公司网站',
          trigger: 'change'
        }]
        */
      }
    }
  },
  computed: {
    ...mapGetters('userToken', ['getToken', 'getUserInfo'])
  },
  mounted () {
    this.initData() // 数据初始化
  },
  methods: {
    ...mapMutations('userToken', ['setUserInfo']),
    initData () {
      this.ruleForm.name = this.getUserInfo.name;
      this.ruleForm.email = this.getUserInfo.email;
      this.ruleForm.phone = this.getUserInfo.phone;
      this.ruleForm.company = this.getUserInfo.company;
      this.ruleForm.website = this.getUserInfo.website;
    },
    submitForm (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.dataSubmit();
        } else {
          this.$message({ type: 'error', message: '不满足提交条件!' });
        }
      })
    },
    dataSubmit () {
      let form_data = new FormData();
      form_data.append('_method', 'PUT');
      form_data.append('token', this.getToken);
      if (this.ruleForm.name !== undefined && this.ruleForm.name !== null && this.ruleForm.name.length > 0) {
        if (this.ruleForm.name.replace(/\s*/g, '') !== '') {
          form_data.append('name', this.ruleForm.name);
        } else {
          this.$message({ type: 'error', message: '账号名称不可为空格' });
          this.ruleForm.name = this.getUserInfo.name;
        }
      } else {
        this.$message({ type: 'error', message: '账号名称不可为空' });
      }
      form_data.append('email', this.ruleForm.email);
      if (this.ruleForm.password !== undefined && this.ruleForm.password !== null && this.ruleForm.password.length > 0) {
        this.ruleForm.password = this.ruleForm.password.replace(/\s*/g, '');
        if (this.ruleForm.password !== '') {
          form_data.append('password', this.ruleForm.password);
        } else {
          this.$message({ type: 'error', message: '密码不可为空格' });
        }
      }
      form_data.append('phone', this.ruleForm.phone);
      form_data.append('company', this.ruleForm.company);
      form_data.append('website', this.ruleForm.website);
      const self = this;
      self.submit_loading = true;
      self.$http.post(APIs.ADMIN_USER_INFO, form_data)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message);
          self.$message.success('保存成功!')
          self.setUserInfo(data.admin);
          self.initData();
          self.ruleForm.password = '';
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.submit_loading = false; })
    }
  }
}
</script>