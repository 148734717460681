/** 试戴云服务后台登录页面 */
<style lang="less" rel="stylesheet/less" type="text/less">
.v-login-index {
  width: 100%;
  height: 100%;
  padding-top: 10%;
  // background-image: url('http://try-on-api.gz.bcebos.com/open-admin%2Fglasses_login_img.jpg');
  background-image: url('/src/assets/imgs/glasses_login_img.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #fff;
  box-sizing: border-box;
  .login-content {
    width: 400px;
    min-height: 300px;
    max-height: 600px;
    margin: 0 auto;
    /// +++++
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /// +++++
    box-shadow: 0px 0px 10px 1px #cecece;
    border-radius: 30px;
    transition: all .3s 0s ease;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
    &.register {
      width: 550px;
    }
    /*登录框样式*/
    .login-box {
      position: relative;
      width: 100%;
      height: 400px;
      padding: 40px;
      box-sizing: border-box;
      h1 {
        line-height: 80px;
        margin-bottom: 20px;
        text-align: center;
        img {
          width: 50%;
        }
      }
      .form-input {
        & > input {
          padding-left: 40px;
          border-radius: 30px;
        }
        & > .el-input__prefix {
          left: 16px;
        }
      }
      /*checkbox样式*/
      .el-checkbox__label {
        color: #f0f0f0;
      }
      /*checkbox样式 END*/
      /*忘记密码样式*/
      a {
        font-size: 13px;
        color: #f0f0f0;
        &.right {
          float: right;
          transition: color .3s 0s ease;
          &:hover {
            color: #409EFF;
          }
        }
      }
      /*忘记密码样式 END*/
      /*登录按钮*/
      .login-btn {
        width: 100%;
        margin-top: 10px;
      }
      /*登录按钮 END*/
      /*注册*/
      .register {
        width: 100%;
        text-align: center;
        cursor: pointer;
        transition: color .3s 0s ease;
        &:hover {
          color: #409EFF;
        }
      }
      /*注册*/
    }
    /*登录框样式 END*/
    /*注册框样式*/
    .register-box {
      position: relative;
      width: 100%;
      height: 600px;
      padding: 40px;
      box-sizing: border-box;
      .el-form-item__label {
        color: #fff;
      }
    }
    /*注册框样式 END*/
  }
}
</style>

<template>
  <div class="v-login-index">
    <div class="login-content" :class="{'register': !is_login}" v-loading="loading_show">
      <!-- element过度动画 -->
      <transition name="el-zoom-in-top">
        <!-- 登录框 -->
        <div class="login-box" v-show="is_login">
          <h1>试戴云服务后台</h1>
          <login-form @forget="forgetPassword"
                      @post="loginRequest"></login-form>
          <!-- <p class="register" @click="changeFormType">没有账户？立即注册></p> -->
        </div>
      </transition>
      <transition name="el-zoom-in-bottom">
        <!-- 注册框 -->
        <div class="register-box" v-show="!is_login">
          <register-form @post="registerRequest"
                         @change="changeFormType"></register-form>
        </div>
      </transition>
    </div>
    <!-- 对话框 -->
    <el-dialog title="找回密码"
               :visible.sync="show_dialog"
               width="400px"
               v-loading="dialog_loading">
      <forget-form @post="resetPasswordPost"></forget-form>
    </el-dialog>
  </div>
</template>

<script type="text/babel">
// =====ok.start
import loginForm from './login_form-new'
import registerForm from './register_form'
import forgetForm from './forget_form'
// =====ok.end
import API_LIST from '../../API_LIST.js'
import APIs from '../../APIs.js'
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapActions, mapMutations } from 'vuex'
import { formaturl } from '../../utils/utils.js'
export default {
  // =====ok.start
  data () {
    return {
      show_dialog: false, // 是否显示弹框
      dialog_loading: false, // 弹框的loading
      loading_show: false, // 是否显示loading框
      is_login: true, // 当前是否是登录框
      img_arrs: [] // 预加载的图片数组
    }
  },
  components: {
    loginForm,
    registerForm,
    forgetForm
  },
  // =====ok.end
  mounted () {
    const url_param = formaturl()
    if (!url_param || !url_param.active_code) return
    this.judgeRegister(url_param.active_code)
  },
  methods: {
    // =====ok.start
    // action的引用
    ...mapActions('userToken', [
      'toSetToken',
      'toSetUserInfo'
    ]),
    ...mapMutations('menu', [
      'setMenus',
      'clearCache'
    ]),
    /** 弹出忘记密码的弹框 */
    forgetPassword () {
      this.show_dialog = true
    },
    /** 改变当前form */
    changeFormType () {
      this.is_login = !this.is_login
    },
    // =====ok.end
    /** 重置密码的post请求 */
    resetPasswordPost (param) {
      const self = this
      self.dialog_loading = true
      self.$http.post(API_LIST.FORGET_PASSWORD, param)
        .then(({ data }) => {
          if (parseInt(data.code) !== 0) throw new Error(data.error)
          self.$message({
            type: 'success',
            duration: 600,
            message: '申请重置密码成功！'
          })
          self.$notify.info({
            title: '温馨提示',
            duration: 5000,
            message: '已向您注册的邮箱发了验证邮件，前往点击即可激活'
          })
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.dialog_loading = false })
    },
    /** 登录的请求 */
    loginRequest (param) {
      const self = this
      self.loading_show = true
      self.clearCache()
      self.setMenus('customer')
      self.$http.post(APIs.ADMIN_LOGIN, param)
        .then(({ data }) => {
          if (parseInt(data.code) !== 200) throw new Error(data.error_message)
          self.toSetToken(data.token)
          self.toSetUserInfo(data.admin)
          self.$message({
            type: 'success',
            duration: 600,
            message: '登录成功！'
          })
          setTimeout(self.jumpToHomePage, 600)
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.loading_show = false })
    },
    /** 注册的请求 */
    registerRequest (param) {
      const self = this
      self.loading_show = true
      self.$http.post(API_LIST.ADMIN_REGISTER, param)
        .then(({ data }) => {
          if (parseInt(data.code) !== 0) throw new Error(data.error)
          self.$notify.info({
            title: '温馨提示',
            duration: 5000,
            message: '已向您注册的邮箱发了验证邮件，前往点击即可激活'
          })
          self.is_login = true
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.loading_show = false })
    },
    /** 请求接口判断是否注册成功 */
    judgeRegister (active_code) {
      const self = this
      self.loading_show = true
      self.$http.get(API_LIST.ADMIN_ACTIVATE + '/' + active_code)
        .then(({ data }) => {
          if (parseInt(data.code) !== 0) throw new Error(data.error)
          self.$message({
            message: '您已成功激活账号，请直接登录！',
            type: 'success'
          })
          history.replaceState({}, '', '#/login')
        })
        .catch((err) => {
          self.$message({
            type: 'error',
            message: err.message || err.statusText
          })
        })
        .then(() => { self.loading_show = false })
    },
    // =====ok.start
    /** 登陆后，转跳到首页 */
    jumpToHomePage () {
      this.$router.push({
        path: '/tryon-open/customer'
      })
    }
    // =====ok.end
  }
}
</script>
