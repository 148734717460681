// =====ok.all
/**
 * Vuex 全局状态管理
 * @see https://vuex.vuejs.org/en/
 */

import Vue from 'vue'
import Vuex from 'vuex'
import userToken from './modules/userToken'
import spinShow from './modules/spinShow'
import menu from './modules/menu'

const config = {
  modules: {
    userToken,
    spinShow,
    menu
  }
}

Vue.use(Vuex)

const store = new Vuex.Store(config)

export default store