/** 管理后台 - 首页 - 顶部导航栏 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
.v-nav-header {
  position: relative;
  height: 60px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0,0,0,.1);
  .drop-down {
    margin: 20px 30px 0 0;
    float: right;
  }
  .inline-middle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  /*后台名字的样式*/
  .header-text {
    display: inline-block;
    height: 60px;
    width: 250px;
    line-height: 50px;
    text-align: center;
    font-size: 28px;
    letter-spacing: 8px;
    text-shadow: 5px 5px 8px #ccc;
  }
  /*后台名字的样式 END*/
  /*面包屑样式布局*/
  .breadcrumb-area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 275px;
    right: 200px;
    padding: 10px;
    line-height: 1;
  }
  .breadcrumb-middle {
    padding: 13px 0;
  }
  /*面包屑样式布局 END*/
  .username {
    color: #F56C6C;
    font-weight: bold;
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 10%;
    margin: auto;
  }
}
</style>
<template>
  <div class="v-nav-header layout-header">
    <span class="header-text">试戴云服务</span>
    <div class="breadcrumb-area">
      <el-breadcrumb class="breadcrumb-middle" separator="/">
        <el-breadcrumb-item v-for="breadcrumb in currentBreadcrumb" :to="breadcrumb.href" :key="breadcrumb.href">{{ breadcrumb.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-dropdown class="drop-down" placement="bottom-end" @command="clickDropdown">
      <span class="el-dropdown-link">
        <span>欢迎您，尊敬的<small class="username">{{ getUserInfo.name || '管理员' }}</small></span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="cancel">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
/// import API_LIST from 'src/API_LIST'
export default {
  data () {
    return {
      timmer: null
    }
  },
  computed: {
    ...mapGetters('menu', [
      'getSelectedMenu',
      'getMenus',
      'getUserType'
    ]),
    ...mapGetters('userToken', [
      'getUserInfo'
    ]),
    /**
     * 当前面包屑的展示
     * @return {Array} {面包屑所需数组}
     */
    currentBreadcrumb () {
      const self = this
      const main_path = this.getUserType === 'customer' ? '/tryon-open/customer' : '/tryon-open/admin'
      let breadcrumb = [
        { href: main_path, name: '总览' }
      ]
      const selected_menu = this.getSelectedMenu
      const menus = this.getMenus
      if (selected_menu === main_path) {
        self.setOpenSubmenu([])
        return breadcrumb
      }
      for (let i = 0; i < menus.length; i++) {
        const menu = menus[i]
        if (menu.name === selected_menu) {
          const new_crumb = { href: menu.name, name: menu.text }
          breadcrumb.push(new_crumb)
          self.setOpenSubmenu([])
          break
        } else {
          const submenus = menu.submenus || []
          for (let j = 0; j < submenus.length; j++) {
            const submenu = submenus[j]
            if (submenu.name === selected_menu) {
              const new_crumb = [
                { href: '', name: menu.text },
                { href: submenu.name, name: submenu.text }
              ]
              breadcrumb.push(...new_crumb)
              self.setOpenSubmenu([menu.name])
              break
            }
          }
        }
      }
      return breadcrumb
    }
  },
  methods: {
    ...mapMutations('userToken', [
      'deleteToken'
    ]),
    ...mapMutations('menu', [
      'clearCache',
      'setOpenSubmenu'
    ]),
    /** 点击下拉菜单的事件 */
    clickDropdown (command) {
      switch (command) {
        case 'cancel':
          this.cancelLogin()
          break
      }
    },
    /** 退出登录 */
    cancelLogin () {
      const logout_path = this.getUserType === 'customer' ? '/login' : '/admin-login'
      this.deleteToken()
      this.$message({
        type: 'success',
        duration: 600,
        message: '退出成功！'
      })
      this.clearCache()
      this.$router.push({ path: logout_path })
    }
  }
}
</script>