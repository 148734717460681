/* 登录表单 */
<style lang="less" rel="stylesheet/less" type="text/less" scoped>

</style>

<template>
  <el-form ref="login_form" status-icon :model="login_form_data" :rules="ruleInline">
    <el-form-item prop="email">
      <el-input placeholder="请输入邮箱" class="form-input" v-model="login_form_data.email" @keyup.enter.native="handleSubmit('login_form')">
        <template slot="prefix">
          <img :src="user_icon" class="icon">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input placeholder="请输入密码" type="password" class="form-input" v-model="login_form_data.password" @keyup.enter.native="handleSubmit('login_form')">
        <template slot="prefix">
          <img :src="password_icon" class="icon">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="login-btn" @click="handleSubmit('login_form')" round>登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { setCookie } from '../../cookies.js'
export default {
  name: 'login-form',
  data () {
    return {
      login_form_data: {
        // wuzhidan@shoplazza.com
        // demo@panxsoft.com
        email: '', // 用户名
        // 123456
        password: '', // 密码
        remember: false // 是否记住密码
      },
      user_icon: require('../../assets/icons/user.svg'),
      password_icon: require('../../assets/icons/password.svg'),
      // 登录表单的校验
      ruleInline: {
        email: [
          { required: true, message: '请填写邮箱', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'change' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 提交表单的方法
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.login_form_data.remember) {
            setCookie('user', this.login_form_data.email, 7) // 保存帐号到cookie，有效期7天
            setCookie('pswd', this.login_form_data.password, 7) // 保存密码到cookie，有效期7天
          }
          // 这种方式未开发，后端获取不了值，要用表单提交
          // const param = {
          //   email: this.login_form_data.email, // 用户名
          //   password: this.login_form_data.password // 密码
          // }
          let formData = new FormData();
          formData.append('email', this.login_form_data.email);
          formData.append('password', this.login_form_data.password);
          this.$emit('post', formData);
        } else {
          this.$message({
            type: 'error',
            message: '账号或密码未填'
          })
        }
      })
    }
  }
}
</script>