/// const HOST_URL = 'http://localhost:8111';
/// const HOST_URL = 'http://43.153.60.161:8111';
const HOST_URL = 'https://tryon-abroad.panxsoft.com';

const ADMIN_LOGIN = HOST_URL + '/admin/v1/login';
const HOME_ACCOUNT_INFO = HOST_URL + '/admin/v1/home/account-info';
const HOME_USAGE_INFO = HOST_URL + '/admin/v1/home/usage-info';
const ADMIN_USER_INFO = HOST_URL + '/admin/v1/user-info';
const ADMIN_ACCESS = HOST_URL + '/admin/v1/access';
const GLASSES_2D_ASSET = HOST_URL + '/admin/v1/g2d-page';
const GLASSES_2D_MATERIAL = HOST_URL + '/admin/v1/g2d';
const GLASSES_3D_ASSET = HOST_URL + '/admin/v1/g3d-page';
const GLASSES_3D_MATERIAL = HOST_URL + '/admin/v1/g3d';
const ORDER_DATA_FLOW = HOST_URL + '/admin/v1/order/data-flow';
const ORDER_DATA_BALANCE = HOST_URL + '/admin/v1/order/data-balance';

export default {
  ADMIN_LOGIN,
  HOME_ACCOUNT_INFO,
  HOME_USAGE_INFO,
  ADMIN_USER_INFO,
  ADMIN_ACCESS,
  GLASSES_2D_ASSET,
  GLASSES_2D_MATERIAL,
  GLASSES_3D_ASSET,
  GLASSES_3D_MATERIAL,
  ORDER_DATA_FLOW,
  ORDER_DATA_BALANCE
}