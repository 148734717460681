import axios from 'axios';
import API_LIST from '../API_LIST';
import store from '../vuex/store';
import router from '../router';

import ElementUI from 'element-ui';

// axios.defaults.baseURL = 'https://tryon.xiaojingxiuxiu.com'
const service = axios.create({
  // baseURL: 'http://localhost:8005',
  // baseURL: 'https://tryon.xiaojingxiuxiu.com',
  baseURL: '/api',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})

// 请求拦截器
// axios.interceptors.request.use(axiosRequestResolve, axiosRequestReject);
service.interceptors.request.use(axiosRequestResolve, axiosRequestReject);

// 响应拦截器
// axios.interceptors.response.use(axiosResponseResolve, axiosResponseReject);
service.interceptors.response.use(axiosResponseResolve, axiosResponseReject);

/**
 * 请求发出前处理
 * @param {Object} config
 * @return {*}
 */
function axiosRequestResolve(config) {
    let token = store.getters['userToken/getToken'];
    // @see {@link https://github.com/mzabriskie/axios/issues/209}
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}
/**
 * 对请求错误处理后件
 */
function axiosRequestReject(error) {
    return Promise.reject(error);
}

/**
 * 对响成功处理
 * @param response
 * @return {*}
 */
function axiosResponseResolve(response) {
    checkTokenValidateReload(response);
    return response;
}

/**
 * 发现 token 失效则删除 cookie reload 页面， route 会自动处理重定向到 login 页面
 * @param response
 */
function checkTokenValidateReload(response) {
    if (response.data &&
        parseInt(response.data.code) === API_LIST.TOKEN_ERROR_CODE
    ) {
        store.commit('userToken/deleteToken');
        router.push({ name: 'login' });
    }
}

/**
 * 响应错误处理
 * @param error
 * @return {Promise.<*>}
 */
function axiosResponseReject(error) {
    return Promise.reject(error);
}

// export default axios;
export default service;