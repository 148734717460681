/* 管理后台主页 /
<style lang="less" rel="stylesheet/less" type="text/less" scoped>
@copy-right-height: 48px;
// 整体布局
.layout {
  position: relative; // overflow: hidden;
  .nav-header {
    padding: 0;
  }
  .main-contain {
    flex-wrap: nowrap;
  }
  /*左边菜单样式*/
  .side-menu {
    height: 100%;
    background-color: #545c64;
    overflow-y: auto;
  }
  /*左边菜单样式 END*/
  /*右边内容样式*/
  .content {
    height: 100%;
    overflow-y: auto;
  }
  /*右边内容样式 END*/
  /*底部文字*/
  .footer-text {
    line-height: 60px;
    color: #ccc;
    font-size: 15px;
    text-align: center;
  }
  /*底部文字 END*/
}
</style>
<template>
<div class="v-index layout">
  <el-container>
    <el-header class="nav-header">
      <!-- 顶部导航栏 -->
      <nav-header></nav-header>
    </el-header>
    <el-container class="main-contain">
      <el-aside width="250px" class="side-menu">
        <side-menu></side-menu>
      </el-aside>
      <el-container class="content">
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer class="footer-text">
          2018-{{ year_now }} &copy; Powerd by PanxSoft
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</div>
</template>
<script>
import sideMenu from './side-menu'
import navHeader from './nav-header'
export default {
  components: {
    'side-menu': sideMenu,
    'nav-header': navHeader
  },
  data () {
    return {
      year_now: new Date().getFullYear()
    }
  },
  mounted () {
    const main_container = document.getElementsByClassName('main-contain')[0]
    const nav = document.getElementsByClassName('nav-header')[0]
    const window_height = window.innerHeight || document.body.clientHeight || document.documentElement.clientWidth
    main_container.style.height = window_height - nav.offsetHeight + 'px'
  },
  methods: { }
}
</script>