/* 超管权限菜单配置列表 */
export default [
  {
    name: '/tryon-open/customer',
    icon: 'el-icon-menu',
    text: '总览',
    key: 'all'
  },
  {
    name: '/tryon-open/customer/secret-key',
    icon: 'el-icon-service',
    text: '密钥管理',
    key: 'secret'
  },
  {
    name: '/tryon-open/customer/material',
    icon: 'el-icon-menu',
    text: '素材管理',
    key: 'material',
    submenus: [
      {
        name: '/tryon-open/customer/material/2D',
        icon: 'el-icon-star-on',
        text: '2D素材'
      },
      {
        name: '/tryon-open/customer/material/3D',
        icon: 'el-icon-star-off',
        text: '3D素材'
      }
    ]
  },
  // {
  //   name: '/tryon-open/customer/order',
  //   icon: 'el-icon-document',
  //   text: '服务订单',
  //   key: 'order'
  // },
  {
    name: '/tryon-open/customer/finance',
    icon: 'el-icon-tickets',
    // text: '财务管理',
    text: '数据管理',
    key: 'finance'
  },
  // {
  //   name: '/tryon-open/recharge',
  //   icon: 'el-icon-goods',
  //   text: '充值服务',
  //   key: 'recharge'
  // },
  {
    name: '/tryon-open/customer/userInfo',
    icon: 'el-icon-view',
    text: '个人信息',
    key: 'userInfo'
  }
  // {
  //   name: '/sdk',
  //   icon: 'el-icon-question',
  //   text: 'SDK文档',
  //   key: 'sdk'
  // }
];